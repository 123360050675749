import React, { useState, useEffect } from 'react';

const Importers = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  // Function for handling radio button click
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const [displayPrice, setDisplayPrice] = useState(16999);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 16999   
  };

  const capacityMapping = {
    "1": ''  
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 1) {
      setSelectedYears(1);
      setSelectedPrice(2999);
      setDisplayPrice(2999);
    }
    if (capacity == 2) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(7999);
      setDisplayPrice(7999);
    }
    if (capacity == 3) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(9999);
      setDisplayPrice(9999);
    }
    if (capacity == 4) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }

  };

  useEffect(() => {
    setSelection(capacityMapping[1]);
    setSelectedPrice(16999);
    setSelectedYears(1);
  }, []);

  return (
    <div className="Importers">
      <div className="container bg-white p-3 text-justify mb-3">
        <h6 className="font-bold">Importers:</h6>
        <span>An importer is a person, company, or country that buys goods or services from another country and sells them in their own country.</span>
      </div>

      <div style={{ display: 'none' }}>
        {/* Hidden section (not translated in Tailwind, you might remove it if not needed in UI) */}
        <label className="font-bold" id="exp">Select Turnover <span className=""> *</span></label>
        <div className="form-group">
          <input
            type="radio"
            name="turnover[]"
            onClick={handleCapacityChange}
            value="Turnover Above 20CR"
            defaultChecked // For checked attribute in HTML
          />
          <label className="custom">Turnover Above 20CR</label>
          <div id="vegi1" style={{ fontSize: '12px' }}></div>
        </div>
      </div>

      <table className="table-style">
        <tbody>
          <tr>
            <td className="cell-style">
              <span className="text-black font-bold text-lg pricing_displayx"> </span>
              <span className="customx">Default Certificate Validity : 1 Year</span>
            </td>
            <td className="cell-style">
              <span className="turnoverType hidden"></span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-lg pricing_display">Applicable Price ₹16999/-<br />Including Taxes &amp; Fees</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Importers;

import React, { useState,useEffect } from 'react';

const SmallPettyFoodBusinessOperators = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  // const [selectedYears, setSelectedYears] = useState('3');
  const [displayPrice, setDisplayPrice] = useState(2999);
  const [displayYear, setDisplayYear] = useState(1);

  const price = {
    '1': 2999,
    '2': 3299,
    '3': 3899,
    '4': 4399,
    '5': 4899,
  };

  const handleYearsChange = (event) => {
    setSelection('');
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value);
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  // useEffect(() => {
  //   const defaultEvent1 = { target: { value: displayYear } };
  //   handleYearsChange(defaultEvent1);

  // }, [displayYear,displayPrice]);

  useEffect(() => {
    setSelection('');
    setSelectedPrice(2999);
    setSelectedYears(1);
  }, []);


  return (
    <div className="container bg-white p-3 text-justify mb-3">
      <h6 className="font-bold">Small / Petty Food Business Operators Description:</h6>
      <p>
        Small / Petty Food Business Operator <b>(FBO)</b> are those who manufacture or sell any kind of food by themselves.
      </p>
      <b>Examples:</b>
      <table className="table-auto border-collapse border border-gray-400 w-full text-center text-sm font-bold">
        <tbody>
          <tr>
            <td className="cell-style border border-gray-300 p-2">Hawker</td>
            <td className="cell-style border border-gray-300 p-2">Pan Shop</td>
            <td className="cell-style border border-gray-300 p-2">Thela</td>
            <td className="cell-style border border-gray-300 p-2">Juice Stall</td>
          </tr>
          <tr>
            <td className="cell-style border border-gray-300 p-2">Temporary Stall</td>
            <td className="cell-style border border-gray-300 p-2">Sweets Shop</td>
            <td className="cell-style border border-gray-300 p-2">Tea / Coffee Stall</td>
            <td className="cell-style border border-gray-300 p-2">Gol gappa stall</td>
          </tr>
          <tr>
            <td className="cell-style border border-gray-300 p-2">Fruit Vendors</td>
            <td className="cell-style border border-gray-300 p-2">Snacks Stall</td>
            <td className="cell-style border border-gray-300 p-2">Samosa Stall</td>
            <td className="cell-style border border-gray-300 p-2">Juice Stall</td>
          </tr>
          <tr>
            <td className="cell-style border border-gray-300 p-2">Chinese Stall</td>
            <td className="cell-style border border-gray-300 p-2">South Indian Stall</td>
            <td className="cell-style border border-gray-300 p-2">Jhalmuri Vendor</td>
            <td className="cell-style border border-gray-300 p-2">Milk Stall</td>
          </tr>
          <tr>
            <td className="cell-style border border-gray-300 p-2">Bread Pakoda Stall</td>
            <td className="cell-style border border-gray-300 p-2">Vegetable Vendors</td>
            <td className="cell-style border border-gray-300 p-2">Any Food Item Retailer</td>
            <td className="cell-style border border-gray-300 p-2">Ice Cream Stall</td>
          </tr>
          <tr>
            <td className="cell-style border border-gray-300 p-2">Fish Shop</td>
            <td className="cell-style border border-gray-300 p-2">Chicken Shop</td>
            <td className="cell-style border border-gray-300 p-2">Egg Shop</td>
            <td className="cell-style border border-gray-300 p-2">Other Small FBO</td>
          </tr>
        </tbody>
      </table>
      <hr className="my-4" />
      <p className="font-bold">
        Basic registration is only applicable for those businesses whose annual turnover(sales) is less than 12 lac per annum.
      </p>
      <p className="text-red-600 font-bold">
        Do not apply under this category if your annual turnover(Sales) is more than 12 lacs. Applications violating FSSAI Law might get rejected.
      </p>
      <p>
        If your turnover exceeds Rs 12 lac per annum, please <a href="#" onClick={() => alert('Redirect to different category selection')}>click here</a> to apply under a different category. Or select your category from the above-given <b>Kind Of Business dropdown menu</b>.
      </p>

      <div className="hidden">
        <label className="font-bold" id="exp">Select The Turnover / टर्नओवर का चयन करें <span>*</span></label>
        <div className="form-group">
          <input type="radio" name="turnover[]" id="new" value="Turnover Upto 12 Lacs" checked readOnly />
          <label htmlFor="new" className="custom">Turnover Upto 12 Lacs / New Business</label>
        </div>
      </div>

      <table className="table-style">
        <tbody>
          <tr>
            <td className="cell-style">
              <label>Choose Certificate Validity / प्रमाणपत्र वैधता चुनें <span className="required">*</span></label>
              <select value={displayYear}
                className="border border-gray-300 text-gray-900 text-sm rounded-none focus:ring-blue-500 focus:border-blue-500 block w-80 p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" name="years[]" required onChange={handleYearsChange}>
                {/* <option value="">Select Certificate Validity</option> */}
                <option value="1">Certificate validity - 1 Year</option>
                <option value="2">Certificate validity - 2 Years</option>
                <option value="3">Certificate validity - 3 Years</option>
                <option value="4">Certificate validity - 4 Years</option>
                <option value="5">Certificate validity - 5 Years</option>
              </select>
            </td>
            <td className="cell-style">
              <span className="turnoverType hidden">Based on the selection of your inputs <h5 className="text-blue-900 font-bold text-base">FSSAI Basic Registration</h5> is applicable in your case</span>
              <div className="fav">
                <label></label>
                <span className="text-black font-bold text-base">Applicable Price ₹{displayPrice}/-<br />Including Taxes & Fees</span>
              </div>
            </td>
          </tr>
          <tr>
            <td className="cell-style custom" colSpan="2" >Change required Certificate validity from the above dropdown.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SmallPettyFoodBusinessOperators;

import React, { useState, useEffect } from 'react';

const Relabellers = ({ setSelection, setSelectedYears, setSelectedPrice }) => {
  const [selectedCapacityKey, setSelectedCapacityKey] = useState(1);

  const [displayPrice, setDisplayPrice] = useState(9999);
  const [displayYear, setDisplayYear] = useState(1);
  // const [selection, setSelection] = useState('');

  const price = {
    '1': 9999,
    '2': 3299,
    '3': 3899,
    '4': 4399,
    '5': 4899,
  };

  const capacityMapping = {
    "1": 'Turnover upto Rs. 20 Cr / annum',
    '2': 'Turnover more than Rs. 20 Cr / annum'   
  };

  const handleYearsChange = (event) => {
    setSelectedYears(event.target.value);
    setDisplayYear(event.target.value)
    setSelectedPrice(price[event.target.value]);
    setDisplayPrice(price[event.target.value]);
    // Add any additional logic needed for price updates
  };

  const handleCapacityChange = (event) => {
    const capacity = (event.target.value);
    setSelectedCapacityKey(event.target.value);
    setSelection(capacityMapping[event.target.value]);

    if (capacity == 1) {
      setSelectedYears(1);
      setSelectedPrice(9999);
      setDisplayPrice(9999);
    }
    if (capacity == 2) {
      setSelectedYears(1);
      setDisplayYear(1);
      setSelectedPrice(16999);
      setDisplayPrice(16999);
    }   

  };

  useEffect(() => {
    setSelection(capacityMapping[1]);
    setSelectedPrice(9999);
    setSelectedYears(1);
  }, []);
  return (
    <div className="Relabellers">
      <div className="container bg-white p-2 text-justify mb-3">
        <h6 className="font-bold">Relabellers &amp; Repackers:</h6>
        <p>
          <span className="font-bold">Relabellers:</span> A relabellers is a food business operator who buys products from manufacturers and repackages them under their own brand. A relabeler is required to obtain noc from the manufacturer to get relabellers licence.
          <br /><br />
          <span className="font-bold">Repackers:</span> A repackager is a business that takes products from manufacturing units in bigger packs sizes and repacks them in smaller packages as per the prevailing market demand.
        </p>
      </div>
      <div>
        <label className="font-bold" htmlFor="turnover">Select Turnover <span className="text-red-600">*</span></label>

        <div className="form-group">
          <input
            type="radio"
            id="turnover-upto-20cr"
            name="turnover"
            value="1"
            checked={selectedCapacityKey == '1'}
            onChange={handleCapacityChange}
            className="mr-2"
            required
          />
          <label htmlFor="turnover-upto-20cr" className="cursor-pointer">Turnover upto Rs. 20 Cr / annum</label>
          <div id="vegi1" className="text-xs"></div>
        </div>

        <div className="form-group">
          <input
            type="radio"
            id="turnover-more-than-20cr"
            name="turnover"
            value="2"
            checked={selectedCapacityKey == '2'}
            onChange={handleCapacityChange}
            className="mr-2"
            required
          />
          <label htmlFor="turnover-more-than-20cr" className="cursor-pointer">Turnover more than Rs. 20 Cr / annum</label>
          <div id="vegi1" className="text-xs"></div>
        </div>
      </div>
      
        {
          <div className="yearDisplay block">
            <table className="table-style">
              <tbody>
                <tr>
                  <td className="cell-style custom">
                    <span className="text-black font-bold text-lg pricing_displayx"></span>
                    <span className="customx">Default Certificate Validity : {displayYear} Year</span>
                  </td>
                  <td className="cell-style custom">
                    <span className="turnoverType hidden"></span>
                    <div className="fav">
                      <label></label>
                      <span className="text-black font-bold text-lg pricing_display">
                        Applicable Price ₹{displayPrice}/-
                        <br />
                        Including Taxes &amp; Fees
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        }
    </div>
  );
};


export default Relabellers;
